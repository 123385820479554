<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="게시판" tabActive="FAQ" bg="/images/test.jpg">
                <template #title>게시판</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <faq-list />
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import FaqList from "@/components/client/center/faqs/faq-list.vue";

export default {
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        FaqList,
    },
};
</script>
